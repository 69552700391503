.grecaptcha-badge {
  visibility: hidden !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: rgba(1, 3, 20, 1);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::placeholder {
  color: #8688a5 !important;
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .ant-otp .ant-otp-input {
    width: 40px;
  }
}
/* set-up-profile */
.custom-select.no-outline .ant-select-selector:focus,
.custom-select.no-outline .ant-select-selector:active,
.custom-select.no-outline .ant-select-selector-focused {
  outline: none !important;
  box-shadow: none !important;
}

.gradient-right {
  top: 40%;
  right: 0%;
  width: 500px;
  height: 500px;
  background: radial-gradient(
    50% 50% at 85% 49%,
    #f68dff 40%,
    #0368ff 9.2%,
    #7437f6 15.18%,
    #ffffff00 54%
  );
  filter: blur(190px);
  z-index: 0;
}

.right-overwrite {
  filter: blur(680px) !important;
}

@media only screen and (max-width: 1024px) {
  .gradient-right {
    background: radial-gradient(
      50% 50% at 85% 50%,
      #f68dff 30%,
      #0368ff 9.2%,
      #7437f6 15.18%,
      #ffffff00 54%
    );
  }
}
@media only screen and (max-width: 768px) {
  .gradient-right {
    filter: blur(150px);
  }
}
@media only screen and (max-width: 652px) {
  .gradient-right {
    width: 50%;
    background: radial-gradient(
      50% 50% at 85% 50%,
      #f68dff 30%,
      #0368ff 9.2%,
      #7437f6 15.18%,
      #ffffff00 54%
    );
  }
}
@media only screen and (max-width: 425px) {
}

.gradient-left {
  top: 30%;
  height: 500px;
  width: 500px;
  background: radial-gradient(
    100% 70% at 0% 50%,
    #f68dff 40%,
    #0368ff 9.2%,
    #7437f6 15.18%,
    #ffffff00 54%
  );
  filter: blur(200px);
  z-index: 0;
}

.left-overwrite {
  filter: blur(680px) !important;
}

@media only screen and (max-width: 1290px) {
  .gradient-left {
    filter: blur(180px);
  }
}
@media only screen and (max-width: 1140px) {
  .gradient-left {
    background: radial-gradient(
      50% 50% at 7% 49%,
      #f68dff 30%,
      #0368ff 9.2%,
      #7437f6 15.18%,
      #ffffff00 54%
    );
    filter: blur(160px);
  }
}
@media only screen and (max-width: 768px) {
  .gradient-left {
    background: radial-gradient(
      50% 50% at 10% 49%,
      #f68dff 30%,
      #0368ff 9.2%,
      #7437f6 15.18%,
      #ffffff00 54%
    );
  }
}
@media only screen and (max-width: 652px) {
  .gradient-left {
    width: 50%;
    filter: blur(130px);
  }
}

@media only screen and (max-width: 425px) {
  .gradient-left {
    background: radial-gradient(
      50% 50% at 5% 49%,
      #f68dff 30%,
      #0368ff 9.2%,
      #7437f6 15.18%,
      #ffffff00 54%
    );
  }
}

.top-gradient {
  width: 45%;
  left: 25%;
  top: -50px;
  background: linear-gradient(
    to right,
    #032cff 0%,
    #810fff 16%,
    #eb03ff 75%,
    #f6b537 115%
  );
  filter: blur(115px);
  will-change: transform, filter;
  transform: translate3d(0, 0, 0);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .top-gradient {
    filter: blur(115px);
  }
  .dark-gradient {
    filter: blur(70px);
  }
}

@media only screen and (max-width: 768px) {
  .top-gradient {
    width: 100%;
    left: 0%;
    top: -45px;
    background: linear-gradient(
      to right,
      #032cff 0%,
      #810fff 0%,
      #eb03ff 60%,
      #f6b537 200%
    );
    filter: blur(100px);
  }
}

.dark-gradient {
  top: -66px;
  left: 12%;
  width: 67%;
  height: 100%;
  background: linear-gradient(
    to right,
    #032cff 0%,
    #810fff 16%,
    #eb03ff 75%,
    #f6b537 115%
  );
  filter: blur(70px);
  will-change: transform, filter;
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-width: 1024px) {
  .dark-gradient {
    width: 65%;
    left: 11%;
  }
}

.scrolled {
  background-color: rgba(0, 0, 0, 1);
  position: relative;
}

.scrolled:before {
  content: "";
  position: absolute;
  top: 0;
  width: 80%;
  left: 13%;
  height: 74%;
  background: linear-gradient(
    to right,
    #032cff 0%,
    #810fff 16%,
    #eb03ff 75%,
    #f6b537 115%
  );
  filter: blur(59px);
  z-index: -1;
}

.sticky-scrolled {
  background-color: rgba(0, 0, 0, 1);
  position: relative;
}

.sticky-scrolled:before {
  content: "";
  position: absolute;
  top: 20;
  width: 100%;
  left: 0%;
  height: 0%;
  background: linear-gradient(
    to right,
    #032cff 0%,
    #810fff 0%,
    #eb03ff 0%,
    #f6b537 0%
  );
  filter: blur(50px);
  z-index: -1;
}

/* Children scrolling effect */
@media (max-width: 640px) {
  .children-scroll {
    overflow-y: auto;
    height: calc(100vh - 148px);
  }
  .header-wrapper {
    background-color: rgba(0, 0, 0, 1);
  }
}

/* @media (min-width: 768px) {
  .children-scroll {
    overflow-y: hidden;
  }
} */
.header-content {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* for heartbeat animation */
.pulseUnreadMessage {
  background: #6174ff;
  border: 1px solid #6174ff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: 0 0 0 0 rgba(97, 116, 255, 0.4);
  -moz-animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(88, 120, 243, 1);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(88, 120, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(88, 120, 243, 0);
  }
}

/* for chat textarea */
.text-area-scrollbar {
  resize: none; /* Disables the resize handle */
}

.active-top-gradient {
  height: 42.27px;
  width: 61.47px;
  position: absolute;
  top: -12px;
  z-index: 1;
  background: radial-gradient(
    58% 107% at 52% 13%,
    #2be7eb 0%,
    rgba(84, 142, 250, 0.065) 85%,
    rgba(87, 136, 251, 0) 32%
  );
  filter: blur(6px);
}

/* for not found page */
/*Gradient text*/
.gradient-text {
  background-image: linear-gradient(
    to bottom,
    #2aebeb,
    #6174ff,
    #b63cfb,
    #ff47e3
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.outer-text {
  /* font-size: 100px; */
  width: 100%;
  font-family: "Intro", sans-serif;
  font-weight: 800;
  /* text-transform: uppercase; */
  /* Warning: no fallback */
  /* text-shadow: 0px 4px 12px #7437f6cc; */
  background: -webkit-linear-gradient(top, #2aebeb, #6174ff, #b63cfb, #ff47e3);
  -webkit-background-clip: text;
  -webkit-text-stroke: 12px transparent;
  /* color: #232d2d; */
}

.not-found-height {
  height: calc(100vh - 105px);
}

@media (max-width: 640px) {
  .not-found-height {
    height: calc(100vh - 200px);
  }
}

.feed-list-height {
  height: calc(100vh - 170px);
}
@media (max-width: 640px) {
  .feed-list-height {
    height: calc(100vh - 210px);
  }
}

.bo-proposals-list {
  height: calc(100vh - 550px);
}
@media (max-width: 640px) {
  .bo-proposals-list {
    height: calc(100vh - 420px);
  }
}

/* css for all skeleton start*/
/* .skeleton-bg {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.2) 37%,
    rgba(255, 255, 255, 0.1) 63%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
} */
/* css for all skeleton start*/

.search-input::placeholder {
  color: rgba(228, 230, 255, 1) !important;
}

.feed-details-card-height {
  height: calc(100vh - 200px);
  min-height: 670px;
}

.feed-page-height {
  min-height: calc(100vh - 420px);
}

.influencer-my-job-page-height {
  min-height: calc(100vh - 450px);
}

.bo-my-job-height {
  min-height: calc(100vh - 510px);
}
